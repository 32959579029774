// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWiunj-uT07XYPAEbMjnEtsiSY-kHDnO8",
  authDomain: "jasongoebel-eb5f5.firebaseapp.com",
  projectId: "jasongoebel-eb5f5",
  storageBucket: "jasongoebel-eb5f5.appspot.com",
  messagingSenderId: "777599919434",
  appId: "1:777599919434:web:8ab47a32f4f5bb3e5d8db3",
  measurementId: "G-14K3WN88S6",
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebase);
