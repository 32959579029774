import "./App.css";

function App() {
  return (
    <div className="App">
      <p>JMG - Testing CI/CD Prod pipleline deploy</p>
    </div>
  );
}

export default App;
